const { h, uniqueTag, Component, define, apis, createRef, render } = omii;
import security from "./security";
import css from "./css/login.scss";
import { codeController, labelsController } from "./apis";
import Cookies from "js-cookie";

import { setToken, getToken, isTokenExpired } from "./token"

import labels from "./components/labels"

import homePage from "./home";
define("oi-labels", labels)

let images = [];
let telValidator = "^\\d{1,4}-\\d{1,4}-\\d{5,9}(-\\d{1,4})?$";
// let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@([a-zA-Z0-9_-])+\\.([a-zA-Z]{2,4})$";
let emailValidator = "^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$";
let english = "[A-Za-z]+";
let phoneValidator = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
let passValidator = "(?!^[0-9]+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^A-z0-9]+$)^[^\\s\\u4e00-\\u9fa5]{6,}";
let numberValidator = "^-?(?:\\d+|\\d{1,3}(?:,\\d{3})+)?(?:\\.\\d+)?$";
let entNameValidator = "^[^\\s]{6,}$";

define("af-auth", class extends Component {
  type = 1;
  gxqcp = [];
  gzly = [];
  userType = 2;
  tip = "获取验证码";
  disBtn = false;
  repeatTip = "";
  countries = [];
  provinces = [];
  cities = [];
  agree = [];
  zcsmList = [];
  serviceArticle = {};
  ruleArticle = {};
  static css = css;
  registObj = {
    username: "",
    // password: "",
    code: "",
    enterprise: {},
  };
  productSetting = {
    url: "/manage/label/listByKey/ProductsOfInterest",
    area: "focusProduct",
    relation: []
  }
  mainProductSetting = {
    url: "/system/dict/data/type/sc_main_product_type",
    // url: "/manage/label/listByKey/exhibitsCate",
    area: "mainProduct",
    relation: []
  }
  applicationAreaSetting = {
    url: "/system/dict/data/type/jc_busi_area_type",
    // url: "/manage/label/listByKey/surveyApplyArea",
    area: "appliationArea",
    relation: []
  }
  serviceAreaSetting = {
    url: "/system/dict/data/type/yy_serve_area_type",
    // url: "/manage/label/listByKey/surveyServiceArea",
    area: "serviceArea",
    relation: []
  }
  otherAreaSetting = {
    url: "/system/dict/data/type/other_enterprise_type",
    // url: "/manage/label/listByKey/MemBusinessOther",
    area: "otherArea",
    relation: []
  }

  focusSetting = {
    url: "/manage/label/listByKey/Areass",
    area: "focusArea",
    relation: []
  }
  entPropsSetting = {
    // url: "/manage/label/listByKey/EnterpriseType",
    url: "/system/dict/data/type/enterprise_type",
    area: "EnterprisetypeArea",
    relation: []
  }
  serviceSetting = {
    width: "880px",
    height: "600px",
    show: false,
    title: "服务条款"
  }
  ruleSetting = {
    width: "880px",
    height: "600px",
    show: false,
    title: "服务细则"
  }
  mainProductStatus = 0
  applicationAreaStatus = 0
  serviceAreaStatus = 0
  otherAreaStatus = 0;
  codeRef = createRef();
  phoneRef = createRef();
  refreshCode() {
    codeController.get().then((r) => {
      this.codeRef.current.src = `data:image/jpg;base64,${r.img}`;
      this.registObj.uuid = r.uuid;
    });
  }
  userInfo() {
    // fetch("/prod-api/system/user/getInfoNew", {
    fetch("/prod-api/system/user/getInfo", {
      method: "get",
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => response.json())
      .then(res => {
        if (res.user) {
          this.registObj.username = res.user.userName;
          this.registObj.phone = res.user.phonenumber;
        }
        else {
          alert("您还没有登录！", function () {
            location.href = "/";
          });
        }
        // console.log(this.registObj.username)
        this.update();
      })
  }
  changeTextarea(e, obj, name) {
    // if (this[obj][name] == undefined) {
    //   this[obj][name] = "";
    // }
    this.registObj.enterprise.entProfile = e.target.value;
    // this[obj][name] = e.target.value;
  }
  //检查统一代码是否已注册
  checkCreditCodeReg() {
    let that = this;
    var myreg = /^[a-zA-Z0-9]{18}$/
    if (myreg.test(this.registObj.creditCode)) {
      if (this.registObj.creditCode != undefined && this.registObj.creditCode != null && this.registObj.creditCode != '') {
        fetch(`/prod-api/manage/enterpriseNew/checkIsCreditCodeReg?creditCode=${this.registObj.creditCode}`, {
          method: 'get',
        })
          .then(response => response.json())
          .then(data => {
            if (data.code == 200) {
              if (data.data != 0) {
                alert("统一代码 “" + this.registObj.creditCode + "”已注册！")
                this.$$("#creditCode")[0].value = '';
                this.registObj.creditCode = '';
                this.update();
              }
            } else {
              alert(data.msg)
            }
          })
      }
    }else{
      alert('请输入正确格式的统一代码')
    }
  }
  reset() {
    this.$$("input.form-input").map(item => item.value = '')
    this.update();

  }
  submitForm() {
    let flag = true;
    if (this.agree.length == 0) {
      alert("请同意“服务条款”和“服务细则”");
      return false;
    }
    if (this.repeatTip != '') {
      return false;
    };
    for (var i = 0; i < this.$$(".required").length; i++) {
      let item = this.$$(".required")[i];
      let name = item.getAttribute("name");
      let nameArr = name.split(".");
      let currentValue = this.registObj[nameArr[0]];
      if (nameArr.length > 1) {
        currentValue = this.registObj[nameArr[0]][nameArr[1]];
      }
      if (!currentValue) {
        flag = false;
        let msgNode = item.parentNode.querySelector("span.errorTip");
        if (!msgNode) {
          let span = document.createElement("span");
          span.className = "errorTip";
          span.innerText = item.dataset.msg;
          item.parentNode.appendChild(span);
        }
      } else {
        let msgNode = item.parentNode.querySelector("span.errorTip");
        let reg = item.dataset.reg;
        if (reg) {
          reg = new RegExp(reg);
          if (!reg.test(currentValue)) {
            if (msgNode) {
              flag = false;
              msgNode.innerText = item.dataset.tip;
            } else {
              let span = document.createElement("span");
              span.className = "errorTip";
              span.innerText = item.dataset.tip;
              item.parentNode.appendChild(span);
            }
          } else {
            if (msgNode) {
              item.parentNode.removeChild(msgNode)
            }
          }
        } else {
          // console.log(123)
          if (msgNode) {
            item.parentNode.removeChild(msgNode)
          }
        }
      }
    }
    if (flag) {
      this.registObj.mainProduct = [];
      this.registObj.focusArea = [];
      this.registObj.enterprise.address = this.registObj.address;

      let obj = JSON.parse(JSON.stringify(this.registObj));

      let obj1 = {};
      obj1.enterprise = obj.enterprise;
      obj.enterprise.type = 1;
      obj1.user = obj;
      // delete obj1.user.enterprise;

      let countryCode = this.registObj.enterprise.countryCode;
      let country = "";
      if (countryCode == "156") {
        country = "中国大陆";
      } else if (countryCode == "344") {
        country = "中国香港";
      } else if (countryCode == "446") {
        country = "中国澳门";
      } else if (countryCode == "158") {
        country = "中国台湾";
      }

      let mainProductArr = [];
      let applicationAreaArr = [];
      let serviceAreaArr = [];
      let otherAreaArr = [];
      let enterpriseTypeArr = [];


      obj.enterprise.enterpriseType.map(item => {
        enterpriseTypeArr.push(item.labelId)
      })


      if (this.registObj.enterprise.enterpriseType.find(a => a.labelName.indexOf("生产") >= 0)) {
        this.registObj.enterprise.mainProduct.map(item => {
          mainProductArr.push(item.labelId)
        })
      }

      if (this.registObj.enterprise.enterpriseType.find(a => a.labelName.indexOf("集成") >= 0)) {
        this.registObj.enterprise.applicationArea.map(item => {
          applicationAreaArr.push(item.labelId)
        })
      }

      if (this.registObj.enterprise.enterpriseType.find(a => a.labelName.indexOf("运营") >= 0)) {
        this.registObj.enterprise.serviceArea.map(item => {
          serviceAreaArr.push(item.labelId)
        })
      }

      if (this.registObj.enterprise.enterpriseType.find(a => a.labelName.indexOf("其它") >= 0)) {
        this.registObj.enterprise.otherArea.map(item => {
          otherAreaArr.push(item.labelId)
        })
      }


      obj1.enterprise.country = country;
      obj1.code = this.registObj.code;
      obj1.uuid = this.registObj.uuid;
      obj1.userType = "03";
      obj1.userSource = '11';

      if (enterpriseTypeArr.length > 0) {
        obj1.enterprise.enterpriseType = enterpriseTypeArr.join("@")
      } else {
        obj1.enterprise.enterpriseType = "";
      }
      if (mainProductArr.length > 0) {
        obj1.enterprise.mainProduct = mainProductArr.join("@")
      } else {
        obj1.enterprise.mainProduct = "";
      }
      if (applicationAreaArr.length > 0) {
        obj1.enterprise.applicationFields = applicationAreaArr.join("@")
      } else {
        obj1.enterprise.applicationFields = "";
      }
      if (serviceAreaArr.length > 0) {
        obj1.enterprise.servicesRange = serviceAreaArr.join("@")
      } else {
        obj1.enterprise.servicesRange = "";
      }
      if (otherAreaArr.length > 0) {
        obj1.enterprise.otherArea = otherAreaArr.join("@")
      } else {
        obj1.enterprise.otherArea = "";
      }
      fetch("/prod-api/auth/userEntRegister", {
        method: "post",
        body: JSON.stringify(obj1),
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getToken()}`
        }
      }).then(res => res.json()).then(r => {
        console.log("企业", r)
        if (r.code == 200) {
          alert("注册成功");
          location.href = '/';
        } else {
          alert(r.msg);
        }
      })

    }
  }

  getProducts(e) {
    this.registObj.interestProduct = e.detail;
  }
  getArea(e) {
    this.registObj.focusArea = e.detail;
  }
  getEntProp(e) {
    this.registObj.enterprise.enterpriseType = e.detail;
    let checkMainProduct = e.detail.find(a => a.labelName.indexOf("生产") >= 0);
    let checkApplicationArea = e.detail.find(a => a.labelName.indexOf("集成") >= 0);
    let checkServiceArea = e.detail.find(a => a.labelName.indexOf("运营") >= 0);
    let checkOtherArea = e.detail.find(a => a.labelName.indexOf("其它") >= 0);
    this.mainProductStatus = checkMainProduct ? 1 : 0;
    this.applicationAreaStatus = checkApplicationArea ? 1 : 0;
    this.serviceAreaStatus = checkServiceArea ? 1 : 0;
    this.otherAreaStatus = checkOtherArea ? 1 : 0;
    this.update();
  }
  getMainProduct(e) {
    this.registObj.enterprise.mainProduct = e.detail;
  }
  getServiceArea(e) {
    this.registObj.enterprise.serviceArea = e.detail;
  }
  getApplicationArea(e) {
    this.registObj.enterprise.applicationArea = e.detail;
  }
  getOtherArea(e) {
    this.registObj.enterprise.otherArea = e.detail;
  }
  uploadLogoFile() {
    this.$("#btn-logo-file").click();
  }
  addLogoFile() {
    let fileInfo = this.$("#btn-logo-file").files[0];
    let fileEXT = fileInfo.name.substring(fileInfo.name.lastIndexOf(".") + 1);
    let extNames = "JPG|JPEG|PNG";
    if (extNames.indexOf(fileEXT.toUpperCase()) < 0) {
      alert("请上传jpg或png格式的图片");
      return false;
    }
    if (fileInfo.size > 300 * 1024) {
      alert("请上传300k以内的图片");
      return false;
    }
    let image = new Image();
    image.src = URL.createObjectURL(fileInfo);
    let that = this;
    image.onload = function () {
      let fileObj = new FormData();
      fileObj.append("file", that.$("#btn-logo-file").files[0]);
      fetch("/prod-api/file/upload", {
        method: 'POST',
        body: fileObj,
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.code == 200) {
            that.registObj.enterprise.logo = data.data.url;
            let img = that.$("#logoFile");
            if (!img) {
              img = document.createElement("img");
            }
            img.id = "logoFile";
            img.width = 200;
            img.src = fileUrl + data.data.url;
            that.$("#uploadLogoArea").after(img);
          } else {
            alert(data.msg)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

  }
  getCountries() {
    fetch("/prod-api/manage/country/list", {}).then(response => response.json()).then(r => {
      if (r.code == 200) {
        this.countries = r.data;
        this.update();
      }
    });
  }
  getProvinces() {
    fetch("/prod-api/manage/city/list?parentCode=156", {
    }).then(response => response.json()).then(r => {
      if (r.code == 200) {
        this.provinces = r.data;
        // console.log(r);
      } else {
        this.provinces = [];
      }
    }).then(r1 => {
      if (this.provinces.length > 0) {
        this.registObj.enterprise.province = this.provinces[0].cityCode;
        this.registObj.enterprise.contactAddressProvince = this.provinces[0].cityCode;
        this.getCitys(event, this.provinces[0].cityCode);
      }
    });
  }
  getCitys(e, code) {
    let cityCode;
    if (code) {
      cityCode = code;
    } else {
      cityCode = e.target.value;
    }
    this.registObj.enterprise.province = cityCode;
    this.registObj.enterprise.contactAddressProvince = cityCode;
    fetch("/prod-api/manage/city/list?parentCode=" + cityCode, {
    }).then(response => response.json()).then(r => {
      if (r.code == 200) {
        this.cities = r.data;
      } else {
        this.cities = [];
      }
      if (this.cities.length > 0) {
        this.registObj.enterprise.city = r.data[0].cityCode;
        this.registObj.enterprise.contactAddressCity = r.data[0].cityCode;
      }
      this.update();
    });
  }
  install() {
    this.refreshCode();
    this.userInfo();
    this.getCountries();
    this.getProvinces();
  }

  uploadFile() {
    this.$("#btn-file").click();
  }
  addFile() {
    let fileInfo = this.$("#btn-file").files[0];
    let fileEXT = fileInfo.name.substring(fileInfo.name.lastIndexOf(".") + 1);
    let extNames = "JPG|JPEG|PNG";
    if (extNames.indexOf(fileEXT.toUpperCase()) < 0) {
      alert("请上传jpg或png格式的图片");
      return false;
    }
    if (fileInfo.size > 300 * 1024) {
      alert("请上传300k以内的图片");
      return false;
    }
    let image = new Image();
    image.src = URL.createObjectURL(fileInfo);
    let that = this;
    image.onload = function () {
      // if (image.width > 700) {
      //   alert("图片宽度不要大于700px");
      //   return false;
      // }
      let fileObj = new FormData();
      fileObj.append("file", that.$("#btn-file").files[0]);
      fetch("/prod-api/file/upload", {
        method: 'POST',
        body: fileObj,
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.code == 200) {
            that.registObj.enterprise.licenseCopy = data.data.url;
            let img = that.$("#licenseBook");
            if (!img) {
              img = document.createElement("img");
            }
            img.id = "licenseBook";
            img.width = 200;
            img.src = fileUrl + data.data.url;
            that.$("#uploadArea").after(img);
          } else {
            alert(data.msg)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

  }


  showServiceDialog() {
    this.serviceSetting.show = true;
    let articleObj = this.zcsmList.find(a => a.title == "服务条款");
    if (articleObj) {
      articleObj.content = decodeURI(articleObj.content);
    }
    this.serviceArticle = articleObj;
    this.update();
  }
  showRuleDialog() {
    this.ruleSetting.show = true;
    let articleObj = this.zcsmList.find(a => a.title == "服务细则");
    if (articleObj) {
      articleObj.content = decodeURI(articleObj.content);
    }
    this.ruleArticle = articleObj;
    this.update();
  }

  getArticles() {
    fetch("/prod-api/manage/article/listByTab?tabKey=ZCSM", {
      method: "get",
      headers: {
        "SiteKey": "Portal"
      }
    }).then(response => response.json())
      .then(res => {
        // console.log(res)
        this.zcsmList = res.rows;
        this.update();
      })
  }
  changeCountry(e) {
    console.log(e.target.value);
    this.registObj.enterprise.countryCode = e.target.value;
    this.registObj.enterprise.contactAddressCountry = e.target.value;
    this.update();
  }

  updateService() {
    this.serviceSetting.show = false;
    this.update();
  }
  updateRule() {
    this.ruleSetting.show = false;
    this.update();
  }
  checkEntInfo(e) {
    var entName = this.registObj.enterprise.entName;
    if (entName != undefined && entName != null && entName != '') {
      if(!new RegExp(entNameValidator).test(entName)){
        alert("公司名称需至少6个字（不含空格）！")
        return;
    }
      fetch("/prod-api/manage/enterprise/checkEntByName?enterpriseName=" + entName, {
        method: "get",
        headers: {
          "SiteKey": "Portal"
        }
      }).then(response => response.json())
        .then(res => {
          if (res.data) {
            this.$("#enterpriseName").focus();
            this.repeatTip = "当前企业已有账号,如需使用请联系该企业账号拥有者";
            let contactType = "";
            if (res.data.phonenumber) {
              contactType = res.data.phonenumber;
            } else if (res.data.email) {
              contactType = res.data.email;
            }
            this.repeatTip += contactType + "！";
            this.update();
            return false;
          } else {
            this.repeatTip = "";
          }
          this.update();
        })
    }
  }
  installed() {
    this.getArticles();
  }
  render() {

    return (
      <div class="mainbox">
        <div class="header">
          <div class="regist-box regist-head">
            <div class="regist-top regist-top-left">
              <a href={portalUrl + "?token=" + Cookies.get("Ent-Token")}><span class="regist-logo"></span></a>
              {/* <span class="white22">账号注册</span> */}
            </div>
            <p class="noaccount">企业认证
            </p>
          </div>
        </div>
        <div class="regist-box regist-box-main" style="margin-top:2rem">
          <div class="regBox">
            <div className="info-box">
              <i className="info-icon"></i>
              <div className="info-title info-title-gsxx"></div>
              {
                this.userType == 2 ? (
                  <div className={this.userType == 1 ? 'hide' : ''}>
                    <div class="fullrow">
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>公司名称：</span>
                        <input type="text" id="enterpriseName" className="form-input required" onBlur={evt => {
                          this.checkEntInfo(evt);
                        }} o-model="registObj.enterprise.entName" name="enterprise.entName" placeholder={'请填写公司全称'} data-msg="请输入公司名称" data-tip="请输入正确格式的公司名称（不含空格）" maxlength="100"/>
                        <span className="tip">【格式要求：需与营业执照一致, 且至少6个字（不含空格）】</span>
                        {
                          this.repeatTip != '' ? <span style="color:red">{this.repeatTip}</span> : ""
                        }
                      </div>
                      {/* <div className="col12">
                        <span className="form-label"><i className="red">*</i>公司英文名称：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.entEnName" name="enterprise.entEnName" data-msg="只能输入英文" data-reg={english} data-tip="请输入英文" />
                      </div> */}
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>统一代码：</span>
                        <input type="text" className="form-input required" id="creditCode" o-model="registObj.enterprise.creditCode"
                          name="enterprise.creditCode" data-msg="请输入统一代码" data-tip="请输入正确格式的统一代码" onBlur={evt => { this.checkCreditCodeReg() }} />
                        <span className="tip">【格式要求：统一代码由18位数字或英文字母组成】</span>
                      </div>
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>企业性质：</span>
                        <select name="enterprise.enterpriseProperty" placeholder="选择其它国家" className="form-select required" o-model="registObj.enterprise.enterpriseProperty" data-msg="请选择企业性质">
                          <option value="">请选择</option>
                          <option value="01">国有</option>
                          <option value="02">民营</option>
                          <option value="03">中外合资</option>
                          <option value="04">外商独资</option>
                          <option value="05">其他</option>
                        </select>
                      </div>
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>注册资金（万元）：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.regCapital"
                          name="enterprise.regCapital" data-msg="请输入注册资金" data-reg={numberValidator} data-tip="请输入正确格式的数值" />
                      </div>
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>成立日期：</span>
                        <input type="date" datetimeformat="yyyy-MM-dd" name="enterprise.foundDate" o-model="registObj.enterprise.foundDate" className="form-input required" placeholder="成立日期" data-msg="请选择成立日期" />
                      </div>
                      <div class="fullRow">
                        <div className="col24">
                          <span className="form-label"><i className="red">*</i>企业类型：</span>
                          <div class="required" name="enterprise.enterpriseType" data-msg="请选择您的企业性质">
                            <oi-labels settings={this.entPropsSetting} onGot={evt => {
                              this.getEntProp(evt)
                            }} o-model="registObj.enterprise.enterpriseType"></oi-labels>
                          </div>
                          {/* <input type="text" className="form-input required" o-model="registObj.enterprise.entProperty" name="enterprise.entProperty" data-msg="请选择企业性质" /> */}
                        </div>
                        {
                          this.mainProductStatus == 1 ?
                            <div className="col24">
                              <table className="optionsTable">
                                <tr>
                                  <td class="form-table-title">生产制造企业</td>
                                  <td>
                                    <p><span className="form-label" style="float:none"><i className="red">*</i>主营产品：</span>
                                    </p>
                                    <div class="required" name="enterprise.mainProduct" data-msg="请选择您的主营产品">
                                      <oi-labels settings={this.mainProductSetting} onGot={evt => {
                                        this.getMainProduct(evt)
                                      }} o-model="registObj.enterprise.mainProduct"></oi-labels>
                                    </div></td>
                                </tr>
                              </table>

                            </div>
                            : <div />
                        }
                        {
                          this.applicationAreaStatus == 1 ?
                            <div className="col24">
                              <table className="optionsTable">
                                <tr>
                                  <td class="form-table-title">系统集成企业</td>
                                  <td>
                                    <p>
                                      <span className="form-label" style="float:none"><i className="red">*</i>应用领域：</span>
                                    </p>
                                    <div class="required" name="enterprise.applicationArea" data-msg="请选择您的应用领域">
                                      <oi-labels settings={this.applicationAreaSetting} onGot={evt => {
                                        this.getApplicationArea(evt)
                                      }} o-model="registObj.enterprise.applicationArea"></oi-labels>
                                    </div></td>
                                </tr>
                              </table>

                            </div> : <div />
                        }
                        {
                          this.serviceAreaStatus == 1 ? <div className="col24">
                            <table className="optionsTable">
                              <tr>
                                <td class="form-table-title">运营服务企业</td>
                                <td>
                                  <p>
                                    <span className="form-label" style="float:none"><i className="red">*</i>服务领域：</span>
                                  </p>
                                  <div class="required" name="enterprise.serviceArea" data-msg="请选择您的服务领域">
                                    <oi-labels settings={this.serviceAreaSetting} onGot={evt => {
                                      this.getServiceArea(evt)
                                    }} o-model="registObj.enterprise.serviceArea"></oi-labels>
                                  </div></td>
                              </tr>
                            </table>
                          </div> : <div />
                        }
                        {
                          this.otherAreaStatus == 1 ? <div className="col24">
                            <table className="optionsTable">
                              <tr>
                                <td class="form-table-title">其它</td>
                                <td>
                                  <p>
                                    <span className="form-label" style="float:none"><i className="red">*</i>其他：</span>
                                  </p>
                                  <div class="required" name="enterprise.otherArea" data-msg="请选择您的业务方向">
                                    <oi-labels settings={this.otherAreaSetting} onGot={evt => {
                                      this.getOtherArea(evt)
                                    }} o-model="registObj.enterprise.otherArea"></oi-labels>
                                  </div></td>
                              </tr>
                            </table>
                          </div> : <div />
                        }
                      </div>
                      <div className="col24">
                        <span className="form-label"><i className="red">*</i>企业简介：</span>
                        <textarea className="form-input required" name="enterprise.entProfile" data-msg="请输入企业简介" style="height:auto;"
                          onChange={evt => {
                            this.changeTextarea(evt, "registObj.enterprise", "entProfile")
                          }}></textarea>
                      </div>
                      {/* <div className="col12">
                        <span className="form-label"><i className="red">*</i>注册地址：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.regAddress" name="enterprise.regAddress" data-msg="请输入注册地址" />
                      </div> */}
                      {/* <div className="col12">
                        <span className="form-label">邮政编码：</span>
                        <input type="text" className="form-input" o-model="registObj.enterprise.postcode" name="enterprise.postcode" data-msg="请输入邮政编码" />
                      </div> */}
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>法人代表：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.legalPerson" name="enterprise.legalPerson" data-msg="请输入法人代表" />
                      </div>
                      {/* <div className="col12">
                        <span className="form-label"><i className="red">*</i>法人电子邮件：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.email" name="enterprise.email" data-msg="请输入电子邮件" data-reg={emailValidator} data-tip="请输入正确格式的邮箱" />
                      </div> */}
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>法人联系电话：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.legalPersonPhone"
                          name="enterprise.legalPersonPhone" data-msg="请输入法人联系电话" />
                        {/* <span className="tip">【格式要求：86-10-63440401,注意横线要在英文状态输入】</span> */}
                      </div>
                      {/* <div className="col12">
                        <span className="form-label">法人手机：</span>
                        <input type="text" className="form-input" o-model="registObj.enterprise.legalPersonMobile" name="enterprise.legalPersonMobile" />
                        <span className="tip">【格式要求：010-26737123-888,注意横线要在英文状态输入】</span>
                      </div> */}
                    </div>
                    {/* <div className="col12">
                      <span className="form-label">企业微信公众号：</span>
                      <input type="text" className="form-input " o-model="registObj.enterprise.wechat" name="enterprise.wechat" data-msg="请输入企业微信公众号" />
                    </div>
                    <div className="col12">
                      <span className="form-label">网址：</span>
                      <input type="text" className="form-input" o-model="registObj.enterprise.url" name="enterprise.url" data-msg="请输入网址" />
                    </div> */}

                  </div>) : (
                  <div></div>
                )
              }
              <div className="col24">
                <span className="form-label"><i className="red">*</i>国家或地区：</span>
                <div class="required" data-msg="请选择国家或地区" name="countryCode">
                  <label htmlFor="">
                    <input type="radio" onClick={evt => {
                      this.changeCountry(evt)
                    }} value="156" name="country" o-model="registObj.countryCode" />中国大陆
                  </label>
                  <label htmlFor="">
                    <input type="radio" onClick={evt => {
                      this.changeCountry(evt)
                    }} value="344" name="country" o-model="registObj.countryCode" />中国香港
                  </label>
                  <label htmlFor="">
                    <input type="radio" onClick={evt => {
                      this.changeCountry(evt)
                    }} value="446" name="country" o-model="registObj.countryCode" />中国澳门
                  </label>
                  <label htmlFor="">
                    <input type="radio" onClick={evt => {
                      this.changeCountry(evt)
                    }} value="158" name="country" o-model="registObj.countryCode" />中国台湾
                  </label>
                  <label htmlFor="">
                    <input type="radio" onClick={evt => {
                      this.changeCountry(evt)
                    }} value="5" name="country" o-model="registObj.countryCode" />其它
                  </label>
                  <span className={this.registObj.enterprise.countryCode != 5 ? 'hide' : 'countries col24'}>
                    <select name="" placeholder="选择其它国家" class="form-select" o-model="registObj.otherCountry">
                      {this.countries.map(item => {
                        return (
                          <option value={item.countryCode}>{item.countryName}</option>
                        )
                      })}
                    </select>
                  </span>
                </div>
              </div>

              <div className={this.registObj.enterprise.countryCode != 156 ? 'hide' : 'countries col24'}>
                <span className="form-label"><i className="red">*</i>省份或地区：</span>
                <div>
                  <div className="col12">
                    <select name="" class="form-select" onChange={evt => {
                      this.getCitys(evt)
                    }} placeholder="选择省份或地区" o-model="registObj.province">
                      {this.provinces.map(item => {
                        return (
                          <option value={item.cityCode}>{item.cityName}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="col12">
                    <select name="" class="form-select" placeholder="选择城市" id="" o-model="registObj.city">
                      {this.cities.map(item => {
                        return (
                          <option value={item.cityCode}>{item.cityName}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col12">
                <span className="form-label"><i className="red">*</i>公司注册地址：</span>
                <input type="text" className="form-input required" o-model="registObj.address" name="address"
                  data-msg="请输入通讯地址" />
              </div>
              {/* <div className="col12">
                <span className="form-label">邮政编码：</span>
                <input type="text" className="form-input" o-model="registObj.postcode"
                  name="postcode" data-msg="请输入邮政编码" />
              </div> */}
              {/* <div className="col24">
                <span className="form-label"><i className="red">*</i>通讯地址：</span>
                <input type="text" className="form-input required" o-model="registObj.address" name="address" data-msg="请输入地址" />
              </div> */}
              <div className="col24">
                <span className="form-label">
                  {/* <i className="red">*</i> */}
                  营业执照：</span>
                <div  id="uploadArea" name="enterprise.licenseCopy" data-msg="请上传营业执照">
                  <input style="display:none" type="file" name="file" id="btn-file" onChange={evt => {
                    this.addFile()
                  }}>上传</input>
                </div>
                <span class='upload-btn' onClick={evt => {
                  this.uploadFile()
                }}>上传</span>
                <span class="tip">文件大小 ：300K 以内　　　　格式 ：jpg,png</span>
              </div>
              {/* <div className="col24">
                <span className="form-label">Logo：</span>
                <div id="uploadLogoArea" name="enterprise.logo" data-msg="请上传营业执照">
                  <input style="display:none" type="file" name="logofile" id="btn-logo-file" onChange={evt => {
                    this.addLogoFile()
                  }}>上传</input>
                </div>
                <span class='upload-btn' onClick={evt => {
                  this.uploadLogoFile()
                }}>上传</span>
                <span class="tip">文件大小 ：300K 以内　　　　格式 ：jpg,png　</span>
              </div> */}
            </div>

            {
              this.userType == 2 ? (
                <div className={this.userType == 1 ? 'hide' : ''}>
                  <div className="info-box">
                    <i className="info-icon"></i>
                    <div className="info-title info-title-lxrxx"></div>
                    <div className="fullRow">
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>联系人姓名：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.contact" name="enterprise.contact" data-msg="请输入联系人姓名" />
                      </div>
                      {/* <div className="col12">
                        <span className="form-label"><i className="red">*</i>联系人性别：</span>
                        <div className="required" name="enterprise.contactSex" data-msg="请选择联系人性别">
                          <label htmlFor=""><input type="radio" value="1" name="contactSex" o-model="registObj.enterprise.contactSex"></input>先生</label>
                          <label htmlFor=""><input type="radio" value="0" name="contactSex" o-model="registObj.enterprise.contactSex"></input>女士</label>
                        </div>
                      </div> */}
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>联系人地址：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.contactAddress" name="enterprise.contactAddress" data-msg="请输入联系人地址" />
                      </div>
                    </div>
                    <div class="fullRow">

                      
                      {/* <div className="col12">
                        <span className="form-label">联系人邮编：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.contactPostcode" name="enterprise.contactPostcode" data-msg="请输入联系人邮编" />
                      </div> */}
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>联系人联系电话：</span>
                        <input type="text" className="form-input required" o-model="registObj.contactPhone" name="contactPhone" data-msg="请输入联系人联系电话" />
                        {/*<span className="tip">【格式要求：86-10-63440401,注意横线要在英文状态输入】</span>*/}
                      </div>
                      <div className="col12">
                        <span className="form-label"><i className="red">*</i>联系人手机：</span>
                        <input type="text" className="form-input required" o-model="registObj.enterprise.contactMobile" name="enterprise.contactMobile" data-msg="请输入联系人手机" data-tip="请输入正确格式的手机" />
                      </div>
                      <div style="float:left;clear:both;width:100%">
                        <div className="col12">
                          <span className="form-label"><i className="red">*</i>联系人电子邮件：</span>
                          <input type="text" className="form-input required" o-model="registObj.enterprise.contactEmail" name="enterprise.contactEmail" data-msg="请输入联系人电子邮件" />
                        </div>
                        <div className="col12">
                          <span className="form-label"><i className="red">*</i>联系人职务：</span>
                          <input type="text" className="form-input required" o-model="registObj.enterprise.contactPosition" name="enterprise.contactPosition" data-msg="请输入联系人职务" />
                        </div>
                        <div className="col12">
                          <span className="form-label"><i className="red">*</i>所在部门：</span>
                          <input type="text" className="form-input required" o-model="registObj.enterprise.contactDept" name="enterprise.contactDept" data-msg="请输入所在部门" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="info-box">
                    <i class="info-icon"></i><div class="info-title info-title-gxly"></div>
                    <div className="col24">
                      <span className="form-label"><i className="red">*</i>您所关心的产品：</span>
                      <div class="required" name="interestProduct" data-msg="请选择您关心的产品">

                        <oi-labels settings={this.productSetting} onGot={evt => {
                          this.getProducts(evt)
                        }} o-model="productSetting.relation"></oi-labels>

                      </div>
                    </div>
                    <div className="col24">
                      <span className="form-label"><i className="red">*</i>您所关心的领域：</span>
                      <div class="required" name="focusArea" data-msg="请选择您关心的领域">
                        <oi-labels settings={this.focusSetting} onGot={evt => {
                          this.getArea(evt)
                        }} o-model="focusSetting.relation"></oi-labels>
                      </div>
                    </div>
                  </div> */}
                </div>
              ) : (
                <div></div>
              )
            }

            <div className="col12">
              <span className="form-label"><i className="red">*</i>验证码：</span>
              <div style="position:relative">
                <input type="text" className="form-input required" o-model="registObj.code" name="code" data-msg="请输入验证码" />
                <img height="36" ref={this.codeRef} name="codeUrl" src="" onClick={evt => {
                  this.refreshCode();
                }} class="login-code-img codeUrl" />
              </div>

            </div>
            <div className="col24">
              <p className="btn-action">
                <button type="primary" class="btn-submit" onclick={evt => {
                  this.submitForm();
                }} size="mini">提交</button>
                <button type="default" class="btn-cancel" size="mini" onclick={evt => {
                  this.reset();
                }}>重置</button>
              </p>
            </div>
            <div className="col24">
              <label><input type="checkbox" o-model="agree" value="1" />同意“<a href="javascript:void(0)" onClick={evt => {
                this.showServiceDialog()
              }}>服务条款</a>”和“<a href="javascript:void(0)" onClick={evt => {
                this.showRuleDialog()
              }}>服务细则</a>”</label>
            </div>
          </div>
        </div >
        <div class="regist-footer">
          <div class="regist-box" style="text-align: center;height:auto">
            版权所有：中国安全防范产品行业协会 京ICP备07503664号-4   京公网安备 11010802034822号
          </div>
        </div>
        <oi-dialog-box settings={this.serviceSetting}>
          <div className="oi-row">
            <h3 className="info-title">{this.serviceArticle.title}</h3>
            <p className="info-content" unsafeHTML={this.serviceArticle.content}></p>
          </div>
          <div slot="footer" className="text-c">
            <button className="btn-dialog-submit m3t" onClick={evt => {
              this.updateService()
            }}>确 定</button>
          </div>
        </oi-dialog-box>
        <oi-dialog-box settings={this.ruleSetting} >
          <div className="oi-row">
            <h3 className="info-title">{this.ruleArticle.title}</h3>
            <p className="info-content" unsafeHTML={this.serviceArticle.content}></p>
          </div>
          <div slot="footer" className="text-c">
            <button className="btn-dialog-submit m3t" onClick={evt => {
              this.updateRule()
            }}>确 定</button>
          </div>
        </oi-dialog-box>
      </div >
    );
  }
}
)
