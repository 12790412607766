const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import { getToken } from "../token";
import css from "../css/home.scss";

export default class extends Component {
    static css = css;
    dataList = [];
    companyName = "";
    attentionTime = "";
    attentionEndTime = "";
    sourceType = "";
    businessName = "";
    data = {
        total: 0,
        pageSize: 10,
        pageNum: 1
    }
    pageRef = createRef();
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    getPagedList(e) {
        let pageNum = e.detail;
        let url = "/prod-api/manage/personal/getFavoriteList?pageNum=" + pageNum + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.sourceType == 2) {
                            item.url = productUrl + item.businessId
                            item.name = "产品";
                        } else if (item.sourceType == 3) {
                            item.url = solutionUrl + item.businessId;
                            item.name = "方案";
                        } else if (item.sourceType == 5) {
                            item.url = exhProductUrl + item.businessId;
                            item.name = "展品";
                        } else if (item.sourceType == 6) {
                            item.url = exhProductUrl + item.businessId;
                            item.name = "资讯";
                        } else if (item.sourceType == 7) {
                            item.url = reportUrl + "/sjbg/web/jqdcbgxq/" + item.businessId;
                            item.name = "数据报告";
                        } else if (item.sourceType == 8) {
                            item.url = reportUrl + "/sjbg/web/hybgxq/" + item.businessId;
                            item.name = "数据报告";
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                    this.data.pageNum = pageNum;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    getList(num) {
        // let pageNum = e.detail;
        let url = "/prod-api/manage/personal/getFavoriteList?pageNum=" + num + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.sourceType == 2) {
                            item.url = productUrl + item.businessId
                            item.name = "产品";
                        } else if (item.sourceType == 3) {
                            item.url = solutionUrl + item.businessId;
                            item.name = "方案";
                        } else if (item.sourceType == 5) {
                            item.url = exhProductUrl + item.businessId;
                            item.name = "展品";
                        } else if (item.sourceType == 6) {
                            item.url = exhProductUrl + item.businessId;
                            item.name = "资讯";
                        } else if (item.sourceType == 7) {
                            item.url = reportUrl + "/sjbg/web/jqdcbgxq/" + item.businessId;
                            item.name = "数据报告";
                        } else if (item.sourceType == 8) {
                            item.url = reportUrl + "/sjbg/web/hybgxq/" + item.businessId;
                            item.name = "数据报告";
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    removeFollow(id, item) {
        if (confirm("确定要取消收藏吗？")) {
            let url = "/prod-api/manage/personal/removeFavorite?favoriteId=" + id;
            if (item.sourceType == 7 || item.sourceType == 8) {
                url = sjbgUrl+"dabg/sjbg/web/jtbg/collectionCancel/" + item.businessId;
                // url = sjbgUrl+"sjbg/web/jtbg/collectionCancel/" + item.businessId;
                let obj = {
                    url: url,
                    type:item.sourceType
                }
                // fetch(url, {
                //     method: "get",
                //     headers: {
                //         "Content-type": "application/json",
                //         "SiteKey": "Portal",
                //         Authorization: `Bearer ${getToken()}`
                //     }
                // }).then(response => response.json())
                //     .then(res => {
                //         this.getList(1);
                //         this.update();
                //     })
                fetch("/prod-api/manage/request/Commonget", {
                    method: "post",
                    body: JSON.stringify(obj),
                    headers: {
                        "Content-type": "application/json",
                        "SiteKey": "Portal",
                        Authorization: `Bearer ${getToken()}`
                    }
                }).then(response => response.json())
                    .then(res => {
                        this.getList(1);
                        this.update();
                    })
            } else {

                fetch(url, {
                    method: "delete",
                    headers: {
                        "Content-type": "application/json",
                        "SiteKey": "Portal",
                        Authorization: `Bearer ${getToken()}`
                    }
                }).then(response => response.json())
                    .then(res => {
                        this.getList(1);
                        this.update();
                    })
            }
        }

    }
    goDetail(item) {
        if (item.sourceType == 6) {
            let url = "/prod-api/manage/tab/getTabByArticle?articleId=" + item.businessId;
            fetch(url, {
                method: "get",
                headers: {
                    "Content-type": "application/json",
                    "SiteKey": "Portal",
                    Authorization: `Bearer ${getToken()}`
                }
            }).then(response => response.json())
                .then(res => {
                    console.log(res)
                    if (res.data && res.data.length > 0) {
                        let tabKey = res.data[0].key;
                        window.open(portalUrl + "/" + tabKey + "/" + item.businessId);
                    }
                })
        } else {
            window.open(item.url);
        }
    }

    install() {
        this.getList(1);
    }
    query(e) {
        this.getList(1)
    }
    installed() {
    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">我的收藏</div>
                <div className="list-area">
                    <div className="row search-area">
                        <div className="search-col">
                            类型: <select o-model="sourceType" class="normalSelect">
                                <option value="" selected>全部</option>
                                <option value="2">产品</option>
                                <option value="3">方案</option>
                                <option value="5">展品</option>
                                <option value="6">资讯</option>
                            </select>
                        </div>
                        <div className="search-col">
                            名称: <input o-model="businessName" className="normalInput" placeholder="名称" />
                        </div><button className="search-btn" onClick={e => {
                            this.query()
                        }}>搜索</button>
                    </div>
                    <table class="table-list" cellPadding="0" border="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="index-col">序号</th>
                                <th class="type-col">类型</th>
                                <th>名称</th>
                                <th class="time-col">收藏时间</th>
                                <th class="action-col">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataList.length > 0 ? this.dataList.map((item, i) => (
                                <tr>
                                    <td class="index-col">{i + 1}</td>
                                    <td class="type-col">【{item.name}】</td>
                                    <td> <a style="cursor:pointer" onClick={ev => {
                                        this.goDetail(item);
                                    }} target="_blank">{item.businessName}</a></td>
                                    <td class="time-col">
                                        {item.createTime.replace("T", " ")}
                                    </td>
                                    <td class="action-col">
                                        <span class="action" onClick={evt => {
                                            this.removeFollow(item.favoriteId, item)
                                        }}>
                                            取消收藏
                                        </span>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="5" className="text-c">
                                        暂无数据
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {
                        this.data.total > 0 ? <div className="pager-area">
                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                        </div> : <div />
                    }

                </div>
            </>
        )
    }
}
